<template>
  <div class="integration pb-6 w-full min-h-contain">
    <p class="text-3xl font-bold mb-4">Integrations</p>
    <div class="mb-6">
      <div class="rounded-md bg-white shadow-soft p-4 flex justify-between items-center">
        <div class="flex items-center gap-4">
          <img src="@/assets/images/zoom.png" />
          <div class="flex flex-col gap-2">
            <p class="font-bold">Zoom</p>
            <p class="text-xs font-medium text-neutral-500">Connect Zoom Webinars and Meetings directly with your classes.</p>
          </div>
        </div>
        <Button buttonText="Setting" type="tertiary" @action="goToDetail()" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    Button: () => import(/* webpackChunkName: "Button" */ '@/components/Button/Button')
  },
  methods: {
    goToDetail() {
      this.$router.push('/integrations/zoom')
    }
  }
}
</script>
