var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "integration pb-6 w-full min-h-contain"
  }, [_c('p', {
    staticClass: "text-3xl font-bold mb-4"
  }, [_vm._v("Integrations")]), _c('div', {
    staticClass: "mb-6"
  }, [_c('div', {
    staticClass: "rounded-md bg-white shadow-soft p-4 flex justify-between items-center"
  }, [_vm._m(0), _c('Button', {
    attrs: {
      "buttonText": "Setting",
      "type": "tertiary"
    },
    on: {
      "action": function action($event) {
        return _vm.goToDetail();
      }
    }
  })], 1)])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex items-center gap-4"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/zoom.png")
    }
  }), _c('div', {
    staticClass: "flex flex-col gap-2"
  }, [_c('p', {
    staticClass: "font-bold"
  }, [_vm._v("Zoom")]), _c('p', {
    staticClass: "text-xs font-medium text-neutral-500"
  }, [_vm._v("Connect Zoom Webinars and Meetings directly with your classes.")])])]);
}]

export { render, staticRenderFns }